import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/yousuite.scss"

import SEO from "../../components/seo"
import HeaderImg from "../../assets/img/youSuite/Img1.png"
import Icon1 from "../../assets/img/youSuite/Icon1.png"
import Icon2 from "../../assets/img/youSuite/Icon2.png"
import Icon3 from "../../assets/img/youSuite/Icon3.png"
import Icon4 from "../../assets/img/youSuite/Icon4.png"
import Icon5 from "../../assets/img/youSuite/Icon5.png"
import Icon6 from "../../assets/img/youSuite/Icon6.png"
import Icon7 from "../../assets/img/youSuite/Icon7.png"
import Icon8 from "../../assets/img/youSuite/Icon8.png"
import Icon9 from "../../assets/img/youSuite/Icon9.png"
import Icon10 from "../../assets/img/youSuite/Icon10.png"
import Icon11 from "../../assets/img/youSuite/Icon11.png"
import GroupImg1 from "../../assets/img/youSuite/Img2.png"
import GroupImg2 from "../../assets/img/youSuite/Img3.png"
import TickIcon from "../../assets/img/youSuite/tick.svg"
import HeaderLogoImg from "../../assets/img/compsol/suite_1.svg"
import SingleForm from "../../components/singleForm"

const YousuitePage = () => {
  return (
    <div>
      <SEO title="Yousuite" keywords={[`youpal`, `youpal group`, `youSuite`]} />
      <div className="youSuiteMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                  Regain control over your business
                </h2>
              </div>
            </div>

            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
                Share and collaborate on documents, send and receive email, manage your calendar and have video chats without data leaks. This is Yousuite.
              </p>
            </div>

            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-4 mb-4 mb-md-0 mt-md-5 productButton d-block mx-auto">
                <span>Free demo</span>
              </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>
          </div>
        </section>

        <section className="container firstSection">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            What we offer your business
            </h3>
            <p className="internalNarrowCol">
            Collect documents, share them with your colleagues, send and receive emails, manage your calendar, host chat and video calls and much more. Yousuite is your one stop-shop solution, with no compliance or security risks.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Be In Charge </h4>
              <p>
              You can easily track and monitor movement and access of your data within your company. This is a simple way of controlling both inflow and outflow of the information within your company and your colleagues. Yousuite complies with every legal and business requirement. 
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Be Productive</h4>
              <p>
              It has been proven that working in a shared space can affect productivity and general efficiency.  With all information and documents being in one place the modification and the sharing features are available for every community member. 
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Join Community</h4>
              <p>
              Acknowledging the fact that you are part of a big community, is very important while working towards a common goal. Yousuite gives everyone the opportunity to join a community and share information as well as organise and control it. 
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection analyticsSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                ANALYTICS
                </div>
                <h3 className="text-left">Stay highly productive and stay in control of your actions with our self-hosted platforms </h3>
                <p>
                Yousuite is the perfect home office working platform. It is one of the most comprehensive and sophisticated office working places online. 
                </p>
                <div className="row">
                    <div className="col">
                        <div className="squareHolder"><img src={Icon4} /></div>
                        <h4>Safe and Secure</h4>
                        <p>
                        With Yousuite the uploaded information is safe and sound, as security is our top priority.
                        </p>
                    </div>
                    <div className="col">
                        <div className="squareHolder"><img src={Icon5} /></div>
                        <h4>Time-efficient </h4>
                        <p>
                        Save time and find what you need in one place </p>
                    </div>
                </div>
                <div className="buttonArea"><Link to="/products/request-demo">
            
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>Explore about our main features</h3>
              <p>
              Regain speed and control with Yousuite. </p>
              <div className="row mt-3 mt-md-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon6} />
                    </div>
                    <div className="col sideContent">
                      <h4>Common collaboration platform </h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Security and safety of information</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Flexible and customisable platform </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Know who gets access to your data </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Secure and free video conference </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Secure chat communication  </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection collaborateSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">COLLABORATE</div>
                <h3>Are you ready to join the reliable, GDPR-compliant cloud solution?</h3>
                <p className="mt-2 mt-md-5">
                With Yousuite, keep documents safely in one place, with no risks of leakage or security breach.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Upload any kind of document
                  </div>
                  <div className="col-6 fastFix">
                    <img src={TickIcon} />
                    Monitor access to the information 
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Access shared data
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Host video conference 
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YousuitePage
